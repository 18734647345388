
import { defineStore } from 'pinia'


export const timePicker = defineStore({
    id: 'timePicker',
    state: () => ({
        timePicker: []
    }),
    actions: {
        setTimePicker(date) {
            this.timePicker = date
            console.log("set time:", this.timePicker);
        },

    },
    getters: {
        showTimePicker: (state) => {
            return state.timePicker
        }
    }
})
