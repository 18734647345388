<template>
  <div
    :id="'cem-card-item-inner-' + props.item.id"
    style="width: 100%; height: 100%"
  >
    <div class="table-body" :class="{ noCenter: config_paging }">
      <el-table
        :data="dataTable.data"
        style="width: 100%"
        :id="'table-' + props.item.id"
        class="table-component"
        header-row-class-name="headerUC"
        v-loading="loading"
        :empty-text="props.type === 'dashboard' ? ' ' : $t('t-no-data')"
        @sort-change="fn_tableSortChange"
        @cell-dblclick="dblclickCell"
      >
        <el-table-column
          label="No#"
          width="53"
          align="center"
          v-if="showHasNo && !loading && dataTable.data?.length > 0"
        >
          <template #default="scope">
            <div>
              {{ scope.$index + props.tableRules.offset + 1 }}
            </div>
          </template>
        </el-table-column>
        <template v-for="(col, index) in dataTable.columns" :key="col.index">
          <el-table-column
            v-if="props.listColumnsStatus.includes(col.field_name)"
            :prop="col.field_name"
            :label="col.title_name ? col.title_name : col.title"
            :min-width="col.width || 120"
            :key="col.field_name"
            :align="col.align"
          >
            <template #header>
              <div class="header" :style="`justify-content: ${col.align}`">
                <div v-if="col.icon?.visible">
                  <img
                    v-if="col.icon?.file_id?.length > 0"
                    :src="renderIconColumns(col.icon?.file_id)"
                    alt="icon column"
                  />
                  <img v-else src="@/assets/icon/calendar.svg" alt="calendar" />
                </div>

                <el-tooltip
                  v-if="col.tooltip"
                  class="box-item"
                  effect="dark"
                  :content="col.tooltip"
                  placement="top"
                >
                  <span class="header-tooltip">
                    {{ col.title_name ? col.title_name : col.title }}
                  </span>
                </el-tooltip>
                <span v-else class="header-tooltip">
                  {{ col.title_name ? col.title_name : col.title }}
                </span>
                <div v-if="col.sort" class="table-screen__sort">
                  <i
                    class="ri-arrow-up-s-fill"
                    :class="'sortTableScreen_' + props.item.id"
                    @click="sortTable('asc', col)"
                    :id="col.code + '_' + props.item.id + '_asc'"
                  ></i>
                  <i
                    class="ri-arrow-down-s-fill"
                    :class="'sortTableScreen_' + props.item.id"
                    :id="col.code + '_' + props.item.id + '_desc'"
                    @click="sortTable('desc', col)"
                  ></i>
                </div>
              </div>
            </template>
            <template #default="scope">
              <div
                v-if="
                  scope.row[index] !== null &&
                  scope.row[index] !== undefined &&
                  scope.row[index] !== ''
                "
                class="value"
                :style="`justify-content: ${
                  col.align === 'left'
                    ? 'flex-start'
                    : col.align === 'right'
                    ? 'flex-end'
                    : 'center'
                }`"
              >
                <el-tooltip
                  v-if="
                    dataTable.columns.findIndex(
                      (colIndex) => colIndex.code === col.tooltipColumn
                    ) !== -1
                  "
                  class="box-item"
                  effect="customizedTableScreen"
                  :content="`${markdownToHTML(
                    scope.row[
                      dataTable.columns.findIndex(
                        (colIndex) => colIndex.code === col.tooltipColumn
                      )
                    ]
                  )}`"
                  placement="top"
                  raw-content
                >
                  <p
                    style="margin-bottom: 0px"
                    v-if="
                      col.type != 'rate' &&
                      col.type != 'progress' &&
                      col.data_type == 'DateTime'
                    "
                  >
                    {{ MethodService.formatDate(scope.row[index]) }}
                  </p>
                  <p
                    :style="`margin-bottom: 0px;${
                      col.color ? 'color: ' + col.color + '; ' : ''
                    }${col.align ? 'text-align: ' + col.align + '; ' : ''}${
                      col.fontSize ? 'font-size: ' + col.fontSize + 'px; ' : ''
                    }`"
                    :class="{
                      textBold: col.textBold,
                      textItalic: col.textItalic,
                      textUnderline: col.textUnderline,
                    }"
                    v-if="
                      col.type != 'rate' &&
                      col.type != 'progress' &&
                      (!col.data_type || col.data_type !== 'DateTime')
                    "
                  >
                    <span v-if="col.isHtml" v-html="scope.row[index]"></span>
                    <span v-if="!col.isHtml">{{ scope.row[index] }}</span>
                    <!-- <img src="../../assets/flags/vn.svg" alt="" /> -->
                  </p>
                  <el-rate
                    v-model="scope.row[index]"
                    v-if="col.type == 'rate'"
                    disabled
                    allow-half
                  />
                  <p
                    style="margin-bottom: 0px; width: 100%"
                    v-if="col.type == 'progress'"
                  >
                    {{ scope.row[index] }}%
                  </p>
                  <el-progress
                    :show-text="false"
                    :percentage="scope.row[index]"
                    :color="col.color"
                    v-if="col.type == 'progress'"
                    :stroke-width="6"
                    style="width: 60%"
                  >
                  </el-progress>
                </el-tooltip>
                <div v-else>
                  <p
                    style="margin-bottom: 0px"
                    v-if="
                      col.type != 'rate' &&
                      col.type != 'progress' &&
                      col.data_type == 'DateTime'
                    "
                  >
                    {{ MethodService.formatDate(scope.row[index]) }}
                  </p>
                  <p
                    :style="`margin-bottom: 0px;${
                      col.color ? 'color: ' + col.color + '; ' : ''
                    }${col.align ? 'text-align: ' + col.align + '; ' : ''}${
                      col.fontSize ? 'font-size: ' + col.fontSize + 'px; ' : ''
                    }`"
                    :class="{
                      textBold: col.textBold,
                      textItalic: col.textItalic,
                      textUnderline: col.textUnderline,
                    }"
                    v-if="
                      col.type != 'rate' &&
                      col.type != 'progress' &&
                      (!col.data_type || col.data_type !== 'DateTime')
                    "
                  >
                    <span v-if="col.isHtml" v-html="scope.row[index]"></span>
                    <span v-if="!col.isHtml">{{ scope.row[index] }}</span>
                    <!-- <img src="../../assets/flags/vn.svg" alt="" /> -->
                  </p>
                  <el-rate
                    v-model="scope.row[index]"
                    v-if="col.type == 'rate'"
                    disabled
                    allow-half
                  />
                  <p
                    style="margin-bottom: 0px; width: 100%"
                    v-if="col.type == 'progress'"
                  >
                    {{ scope.row[index] }}%
                  </p>
                  <el-progress
                    :show-text="false"
                    :percentage="scope.row[index]"
                    :color="col.color"
                    v-if="col.type == 'progress'"
                    :stroke-width="6"
                    style="width: 60%"
                  >
                  </el-progress>
                </div>
              </div>
              <div
                v-else
                class="value"
                :style="`justify-content: ${
                  col.align === 'left'
                    ? 'flex-start'
                    : col.align === 'right'
                    ? 'flex-end'
                    : 'center'
                }`"
              >
                <el-tooltip
                  v-if="
                    dataTable.columns.findIndex(
                      (colIndex) => colIndex.code === col.tooltipColumn
                    ) !== -1
                  "
                  class="box-item"
                  effect="customizedTableScreen"
                  :content="`${markdownToHTML(
                    scope.row[
                      dataTable.columns.findIndex(
                        (colIndex) => colIndex.code === col.tooltipColumn
                      )
                    ]
                  )}`"
                  placement="top"
                  raw-content
                  >-</el-tooltip
                >
                <span v-else>-</span>
              </div>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
  </div>
</template>

<script setup>
import * as moment from "moment";
import { defineEmits, defineProps, reactive, watch, ref } from "vue";
import mushroom from "cem-probe-api";
import { Card_item } from "cem-probe-api";
import { GridStack } from "gridstack";
import MethodService from "../../service/MethodService";
// import { changeEdit } from "../../stores/changeEdit";
// const storeChangeEdit = changeEdit();
import { marked } from "marked";

const props = defineProps({
  item: Card_item,
  itemData: null,
  grid: GridStack,
  configTable: Array,
  isLoading: Boolean,
  configPaging: Boolean,
  heightCardContent: Number,
  listColumnsStatus: Array,
  type: String,
  tableRules: Object,
});

const emit = defineEmits(["showDialogDashboardPopup"]);
const loading = ref(false);
const config_paging = ref(props.configPaging);
const showHasNo = ref(false);
let heightTable = ref(0);
window.fnPools = {
  // fnRenderReAuthen: MethodService.fnRenderReAuthen,
  // fnRenderSpeedTest: MethodService.fnRenderSpeedTest,
  // fnRenderService: MethodService.fnRenderService,
  // fnRenderUC53: MethodService.fnRenderUC53,
  // fnRenderUsedHour: MethodService.fnRenderUsedHour,
  // fnRenderServerIP: MethodService.fnRenderServerIP,
  // fnRenderFailCustNumber: MethodService.fnRenderFailCustNumber
};
const dblclickCell = (row, column) => {
  if (props.configTable?.length === 0) return;
  const id =
    props.configTable.filter(
      (configCol) => configCol.field_name === column.rawColumnKey
    )[0]?.popup?.popup_id || undefined;
  const index = props.configTable.findIndex(
    (configCol) => configCol.field_name === column.rawColumnKey
  );
  if (!id || !index) return;
  emit(
    "showDialogDashboardPopupFromTableScreenComponent",
    id,
    props.configTable[index]?.code,
    row[index]
  );
};

const markdownToHTML = (text) => {
  if (text === undefined || text === null || text === "") return "-";
  if (typeof text !== "string") text = text.toString();
  return marked(text);
};

const renderIconColumns = (iconId) => {
  return mushroom.$file.linkBuilder.thumb.id(iconId).build();
};
const sortTable = (type, col) => {
  loading.value = true;
  setTimeout(() => {
    let order = {};

    let domAll = document.querySelectorAll(
      `.table-screen__sort i.sortTableScreen_${props.item.id}`
    );
    let domTypeSort = document.getElementById(
      col.code + "_" + props.item.id + "_" + type
    );
    if (domTypeSort) {
      if (domAll) {
        domAll.forEach((dom) => {
          if (dom !== domTypeSort) dom.classList.remove("active");
        });
      }
      if (domTypeSort.classList.contains("active")) {
        domTypeSort.classList.remove("active");
        order = {};
      } else {
        domTypeSort.classList.add("active");
        let key = col.field_name;
        let value = type === "desc" ? -1 : 1;
        key ? (order[key] = value) : (order = {});
      }
    }
    emit("getDataTableWhenSort", col, order);
  }, 100);
};
const fn_tableSortChange = (column, tableSort) => {
  loading.value = true;
  tableSort = props.tableRules;
  MethodService.tableSortChange(column, tableSort);
  emit("getDataTableWhenSort", column);
};
const dataTable = reactive({ data: [], columns: [] });

watch(
  () => props.configPaging,
  (newConfigPaging) => {
    config_paging.value = newConfigPaging;
  }
);
watch(
  () => props.isLoading,
  (newLoading) => {
    loading.value = newLoading;
  }
);
watch(
  () => props.heightCardContent,
  (newHeight) => {
    heightTable.value = newHeight;
  }
);
watch(
  () => props.item,
  () => {
    showHasNo.value = props.item.table?.hasNo ? true : false;
  }
);

watch(
  () => props.itemData,
  (newData) => {
    setTimeout(() => {
      const idDom = "cem-card-item-inner-" + props.item.id;
      const dom = document.querySelector(`#${idDom} .table-body`);
      if (dom) heightTable.value = dom.offsetHeight;
    }, 500);

    if (props.configTable?.length == 0) {
      dataTable.columns = newData.columns;

      newData.data?.forEach((itemData) => {
        newData.columns?.forEach((col, index) => {
          if (col.type == "rate") {
            if (itemData[index] == 1) {
              return 1.5;
            }
            if (itemData[index] == 5) {
              return 4.5;
            }
          }
        });
      });
      dataTable.data = newData.data;
    } else {
      dataTable.columns.length = 0;
      props.configTable?.forEach((item) => {
        // if (!item.visible) return;

        const col =
          newData?.columns?.find((c) => c.title == item.field_name) ?? {};

        col.code = item.code;
        col.title_name = item.title_name;
        col.field_name = item.field_name;
        col.textBold = item.bold;
        col.textItalic = item.italic;
        col.textUnderline = item.underline;
        col.color = item.color;
        col.align = item.align;
        col.fontSize = item.font_size;
        col.type = item.type || "none";
        col.visible = item.visible;
        col.cellLink = item.cellLink || undefined;
        col.formatString = item.formatString;
        col.isHtml = item.cellLink ?? !!col.formatString;
        col.width = item.width;
        col.sort = item.sort?.is_allowed || false;
        col.icon = {
          visible: item.icon?.visible || false,
          file_id: item.icon?.file_id || "",
        };
        col.tooltip = item.tooltip;
        col.tooltipColumn = item.cell_tooltip?.source_column_code || null;
        dataTable.columns.push(col);
      });

      dataTable.data = newData?.data?.map((row) => {
        return dataTable.columns.map((col) => {
          let formatString = undefined;
          if (col.cellLink) {
            let index = -1;
            newData.columns.some((item, idx) => {
              if (item.field_name === col.cellLink.valueColumn) {
                index = idx;
                return true;
              }
              return false;
            });

            if (index !== -1) {
              let value = row[index];
              if (
                value === null ||
                value === undefined ||
                value === "NA" ||
                value === "-" ||
                value === ""
              )
                return undefined;

              if (typeof value === "number") value = String(row[index]);
              const link = col.cellLink.links.find((x) =>
                x.values.includes(value)
              );
              formatString = link
                ? link.formatString
                : col.cellLink.defaultLink?.formatString;
            }
          }

          if (!formatString) {
            formatString = col.formatString;
          }
          // %=fnRenderReAuthen%
          // ^%=[a-zA-Z0-9_]+%$
          if (formatString?.match(/^%=[a-zA-Z0-9_]+%$/)) {
            let fnName = formatString.replace(/%/g, "").replace("=", "");
            const fn = window.fnPools[fnName];
            if (!fn) return "";

            let index = -1;

            for (let i = 0; i < newData.columns.length; i++) {
              if (col.field_name == newData.columns[i].field_name) {
                index = i;
                break;
              }
            }

            if (index != -1) return fn(row, index);
          }

          if (formatString) {
            // {IPS} - <span style="background-color: red; font-weight: bold">{SoLan:0.00}</span>
            return formatString.replace(
              /\{([^:}]+)(:[^}]*)?\}/g,
              (all, title, format) => {
                let index = -1;
                for (let i = 0; i < newData.columns.length; i++) {
                  if (title == newData.columns[i].title) {
                    index = i;
                    break;
                  }
                }
                if (index === -1 || MethodService.isNoData(row[index]))
                  return "-";
                if (!format) return MethodService.htmlEntities(row[index]);
                let strFormat = format.slice(1);
                if (strFormat === "url") return encodeURIComponent(row[index]);
                if (strFormat === "raw") return row[index];
                if (strFormat === "markdown") return markdownToHTML(row[index]);

                return MethodService.htmlEntities(
                  moment(row[index] * 1000).format(strFormat)
                );
              }
            );
          }

          let index = -1;
          for (let i = 0; i < newData.columns.length; i++) {
            if (col.field_name == newData.columns[i].field_name) {
              index = i;
              break;
            }
          }

          if (index != -1) {
            if (col.type !== "rate") return row[index];

            if (row[index] == 1) return 1.5;
            if (row[index] == 5) return 4.5;
            return row[index];
          }

          let indexRow = -1;
          newData.columns.some((item, idx) => {
            if (item.field_name === col.field_name) {
              indexRow = idx;
              return true;
            }
            return false;
          });
          if (indexRow !== -1) return row[indexRow];

          return undefined;
        });
      });
    }
    loading.value = false;
  }
);
</script>
<style scoped lang="scss">
.table-screen {
  &__sort {
    margin-left: 3px;
    display: flex;
    flex-direction: column;

    i {
      cursor: pointer;
      line-height: 5px;
      font-size: 16px;
      margin: 2px 0;
      transition: 0.2s;

      &:hover {
        color: #407bff;
      }
    }

    i.active {
      color: #407bff;
    }
  }
}

.textBold {
  font-weight: bold;
}

.textItalic {
  font-style: italic;
}

.textUnderline {
  text-decoration: underline;
}

.height100 {
  height: 100%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header img {
  margin-right: 10px;
  height: 16px;
  width: 16px;
}

.header-tooltip {
  color: #222222;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.table-body {
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}

:deep .el-scrollbar__wrap--hidden-default {
  max-height: 100% !important;
}

.height100 {
  height: 100%;
}

:deep .table-component .cell {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: unset;
}

:deep .table-component .caret-wrapper {
  top: 2px;
}

.value {
  display: flex;
  width: 100%;
}

.noCenter {
  align-items: unset;
}
</style>
